import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CustomerStatistics } from '@/api/ceshi_xiugai/whole';
import { ittnyxIndex } from '@/api/user_restPwd/Managesubordinates/department';
import Head from '@/components/head/index';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
export default {
  name: 'PlaceOfAttribution',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '意向院校',
      stime: '',
      etime: '',
      easyDataOption: {
        style: 'width:30%',
        span: 4,
        data: [{
          title: '总数据量',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-zongshu1'
        }, {
          title: '重点意向',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-zhongdian'
        }, {
          title: '外送公海',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-waisongdanweiguanli'
        }, {
          title: '成交数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2cbda790813b1017465cccf9a162afc6.jpg',
          key: 'icon-shichangchengjiaoshuju'
        }, {
          title: '跟进数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2bdf5eccfc829372f28edb8f90b605f4.jpg',
          key: 'icon-genzong'
        }, {
          title: '无效数据',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/de58e9576c4d4f63528e215ef15b8d9b.jpg',
          key: 'icon-wuxiao'
        }]
      },
      chartData: {
        columns: ['日期', '总数据', '新数据', '重点意向'],
        rows: []
      },
      tableData: [],
      region: [],
      //地区
      numberEs: [],
      //客户数量
      proportion: [],
      //总比例
      fds: ''
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList(this.stime, this.etime);
      this.drawLine();
    } else {}
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs
  },
  methods: {
    getList: function getList(stime, etime) {
      var _this = this;
      CustomerStatistics().then(function (respomse) {
        var sum = respomse.data;
        _this.easyDataOption.data[0].count = sum.sum;
        _this.easyDataOption.data[1].count = sum.importance_num;
        _this.easyDataOption.data[2].count = sum.send_out_num;
        _this.easyDataOption.data[3].count = sum.success_num;
        _this.easyDataOption.data[4].count = sum.follow_num;
        _this.easyDataOption.data[5].count = sum.invalid_num;
      });
    },
    drawLine: function drawLine() {
      var _this2 = this;
      //柱形统计图
      ittnyxIndex().then(function (res) {
        _this2.region = [];
        var data = res.data.slice(0, 11);
        data.map(function (item) {
          item.ratio = Number(item.ratio);
          item.value = Number(item.num);
          if (item.ittnyx == '') {
            item.ittnyx = '未知';
          }
        });
        _this2.tableData = data;
        for (var i in data) {
          _this2.region.push(data[i].ittnyx);
        }
        _this2.numberEs = data; //客户数量、总比例
        // 基于准备好的dom，初始化echarts实例
        var myChart = _this2.$echarts.init(document.getElementById('myChart'));
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function formatter(params) {
              return params[0].name + ' <br/>客数量:&nbsp' + params[0].data.value + '<br/>' + '总统计:&nbsp' + params[0].data.ratio + '%';
            }
          },
          xAxis: {
            data: _this2.region
          },
          yAxis: {
            splitLine: {
              show: false
            }
          },
          series: [{
            name: '客户数',
            type: 'bar',
            color: '#1A95FF',
            barWidth: 50,
            itemStyle: {
              emphasis: {
                barBorderRadius: 30
              },
              normal: {
                barBorderRadius: [5, 5, 0, 0],
                label: {
                  show: true,
                  //是否展示
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    fontFamily: '微软雅黑'
                  }
                }
              }
            },
            selectedMode: 'single',
            data: _this2.numberEs
          }]
        });
      });
    },
    screening: function screening(item) {
      //昨天/最近七天/30天
      this.getList(item[0], item[1]);
    }
  }
};